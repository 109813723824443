<template>
  <div class="container">
    <div
      class="action-card elevation-2 is-hover-grow"
      :class="`${left ? 'left' : ''} ${right ? 'right' : ''} ${imageClass} ${image ? '' : 'cta'}`"
      :style="`${image ? `background-image: url(${image})` : ''}`"
      @click="onCardClick"
    >
      <div class="container">
        <div class="action-card-content">
          <h3 v-if="title">
            {{ title }}
          </h3>
          <p>
            <slot></slot>
          </p>
          <div v-if="buttonText" class="button is-primary has-text-weight-bold elevation-2">
            {{ buttonText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActionCard",
  props: {
    title: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: ""
    },
    buttonText: {
      type: String,
      default: ""
    },
    buttonLink: {
      type: String,
      default: ""
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    imageClass: {
      type: String,
      default: ""
    },
    newWindow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onCardClick() {
      if (this.newWindow) {
        let routeData = this.$router.resolve({ name: this.buttonLink });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({ name: this.buttonLink });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/mixins";
.action-card {
  &.left,
  &.right {
    @include landscape {
      text-align: left;
    }
  }
  &.right {
    .container {
      @include landscape {
        display: flex;
        justify-content: flex-end;
      }
    }
    .action-card-content {
      @include landscape {
        width: 48.5%;
      }
      @include desktop {
        width: 44.5%;
      }
    }
  }
  .container {
    width: 100%;
  }
  &.find-an-event-near-you {
    background-position: 50% -145%;
    background-size: 110%;
    @include landscape {
      background-position: -7% 48%;
      background-size: 58%;
    }
    @include desktop {
      background-position: -9% 49%;
      background-size: 63%;
    }
    @include widescreen {
      background-position: -7% 49%;
      background-size: 62%;
    }
  }
  &.sign-up-for-a-challenge {
    background-position: 50% -420%;
    background-size: 108%;
    @include landscape {
      background-position: 109% 50%;
      background-size: 56%;
    }
    @include tablet {
      background-size: 60%;
      background-position: 109% 48%;
    }
    @include desktop {
      background-size: 63%;
      background-position: 110% 48%;
    }
    @include widescreen {
      background-size: 59%;
      background-position: 109% 51%;
    }
  }
  &.explore-writing-prompts {
    background-position: 50% 33vw;
    background-size: 93%;
    @include landscape {
      background-position: -2% 36%;
      background-size: 50%;
    }
    @include desktop {
      background-position: -3% 34%;
      background-size: 56%;
    }
    @include widescreen {
      background-position: -2% 34%;
      background-size: 55%;
    }
  }
  &.become-a-host {
    background-position: 50% 180%;
    background-size: 75%;
    @include landscape {
      background-position: 100% 50%;
      background-size: 58%;
    }
    @include desktop {
      background-position: 97% 54%;
      background-size: 54%;
    }
    @include widescreen {
      background-position: 98% 52%;
      background-size: 57%;
    }
  }
  &.in-person {
    background-position: 50% 46vw;
    background-size: 110%;
    margin-top: vwp(32px);
    @include landscape {
      background-position: 104% 40%;
      background-size: 55%;
      margin-top: vwl(32px);
    }
    @include tablet {
      margin-top: 3.5rem;
      background-size: 54%;
      background-position: 103% 45%;
    }
    @include desktop {
      margin-top: 5rem;
      background-position: 110% 43%;
      background-size: 63%;
    }
    @include widescreen {
      background-position: 106% 44%;
      background-size: 60%;
    }
    .action-card-content {
      @include landscape {
        width: 49%;
      }
      @include desktop {
        width: 44.5%;
      }
    }
    // .button {
    //   &::after {
    //     @include tablet {
    //       content: "\00a0\Our Map";
    //     }
    //   }
    // }
  }
  &.online {
    background-position: 50% 43vw;
    background-size: 115%;
    @include landscape {
      background-position: -7% 51%;
      background-size: 58%;
    }
    @include tablet {
      background-position: -7% 53%;
      background-size: 56%;
    }
    @include desktop {
      background-position: -6% 58%;
      background-size: 59%;
    }
    @include widescreen {
      background-position: -5% 58%;
      background-size: 58%;
    }
  }

  .button {
    min-width: 15em;
    font-weight: 600 !important;
    // margin-top: 1.5em;
  }

  .column {
    padding: 1em 2em;
  }
}
</style>
