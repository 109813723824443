<template>
  <div>
    <Header image-class="events page-header">
      <HeaderPageHeaderCopy slug="events-header" />
    </Header>
    <Section>
      <ActionCard
        title="In-Person Events"
        image-class="in-person event"
        image="/img/inperson_event.svg"
        button-text="Browse Chapters"
        button-link="events_physical"
        left
      >
        Shut Up &amp; Write! began with a group<br class="is-hidden-landscape" />
        of writers meeting in a local coffee shop,<br class="is-hidden-landscape" />
        and we still host in-person events around<br class="is-hidden-landscape" />
        the world. Search our in-person events to<br class="is-hidden-landscape" />
        find a Shut Up &amp; Write! near you.
      </ActionCard>
    </Section>
    <Section>
      <ActionCard
        title="Online Events"
        image-class="online event"
        image="/img/online_event.svg"
        button-text="Browse Our Schedule"
        button-link="events_virtual"
        right
      >
        We offer online Shut Up &amp; Write!<br class="is-hidden-landscape" />
        events via Zoom for those who prefer the<br class="is-hidden-landscape" />
        flexibility of writing remotely. Browse our<br class="is-hidden-landscape" />
        online events by time and date to find an<br class="is-hidden-landscape" /> 
        event that works with your schedule.
      </ActionCard>
    </Section>
    <Section>
      <ActionCard
        v-if="$auth.isAdmin || $auth.isOrganizer"
        title="Organize an Event"
        image-class="event"
        button-text="Create Event Series"
        button-link="event_create"
      >
        Create a new in-person or online series of events!
      </ActionCard>
    </Section>
    <Section v-if="!$apollo.loading">
      <div class="faq container">
        <h2>
          <strong>Questions?</strong><br class="is-hidden-landscape" />
          Here's how this works:
        </h2>
        <Faq
          v-for="c in post.content"
          :key="c.id"
          :icon-url="c.url || (c.media[0] || {}).url"
        >
          <template #question>{{ c.description }}</template>
          <template #answer>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="c.copy.html" />
          </template>
        </Faq>
      </div>
    </Section>
  </div>
</template>

<script>
import { POST } from "@/models/resources/operations.gql";
import Faq from "@/components/resources/Faq.vue";
import Header from "@/components/common/Header.vue";
import Section from "@/components/common/Section.vue";
import ActionCard from "@/components/common/ActionCard.vue";
import HeaderPageHeaderCopy from "@/components/resources/HeaderPageHeaderCopy";

export default {
  name: "Events",
  metaInfo: {
    title: "Writing Groups - ",
    meta: [
      { vmid: "description", name: "description", content: "Find your writing group at a Shut Up & Write event designed so you can focus on getting writing done." }
    ]
  },
  components: {
    Faq,
    Header,
    Section,
    ActionCard,
    HeaderPageHeaderCopy
  },
  data() {
    return {
      post: null
    };
  },
  apollo: {
    post: {
      client: "cms",
      query: POST,
      variables() {
        const stage =
          !!localStorage.showCMSDrafts && JSON.parse(localStorage.showCMSDrafts) ? "DRAFT" : "PUBLISHED";
        return {
          stage,
          where: {
            slug: "event-faq"
          }
        };
      },
      update({ post }) {
        return post;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/mixins";
.page-content {
  padding-bottom: vwp(32px);
  @include landscape {
    padding-bottom: vwl(32px);
  }
  @include tablet {
    padding-bottom: 3rem;
  }
}
.container {
  padding: 0 0.5em;
  &.faq {
    padding-top: 1.5rem;
    @include mobile {
      padding-top: vwl(24px);
    }
    @include portrait {
      padding-top: vwp(24px);
      text-align: center;
    }
    p {
      font-weight: 300;
    }
  }
}
h2 {
  font-weight: 300;
  @media (min-width: $tablet) and (max-width: ($desktop - 1px)) {
    font-size: 1.75rem;
    width: 754px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  @include desktop {
    font-size: 2.25rem;
    margin: 0 0 2rem 0;
  }
  strong {
    font-weight: 500;
  }
}
</style>

<style lang="scss">
@import "../../scss/mixins";
$radius: 0.5em;

.event-type {
  @extend %elevation-2;
  margin: 1em;
  margin-bottom: 5em;
  border-radius: $radius;
  display: flex;
  flex-wrap: nowrap;
  img {
    @include touch {
      display: none;
    }
    height: auto;
    width: auto;
    min-width: 200px;
    min-height: 0;
    max-height: 400px;
    max-width: 443px;
    flex: 0 1 auto;
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }
  .prose {
    flex: 1;
    padding: 1em 2em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
    min-height: 0;

    .header {
      align-self: center;
    }

    .button {
      @extend %elevation-3;
      transform: translateY(75%);
      align-self: center;
      font-size: $size-4;
      @include touch {
        font-size: $size-5;
      }
    }
  }
}
</style>
