<script>
import ChapterCard from "./ChapterCard.vue";
export default {
  name: "ChapterCardGrid",
  components: { ChapterCard },
  props: {
    cards: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<template>
  <div class="suaw-chapter-card-grid-section">
    <ChapterCard 
      v-for="card in cards" 
      :id="card.id" 
      :key="card.id" 
      :url="`https://www.meetup.com/${card.slug}`" 
      :photo-url="card.photoUrl" 
      :title="card.title" 
      :location="`${card.place.city}, ${card.place.region}`" 
      :member-count="card.memberCount" 
      :distance="`${Math.round(card.place.distance)} ${card.place.distanceUnits}`" 
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-chapter-card-grid-section {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 32px;
  justify-content: center;
}
</style>
