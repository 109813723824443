<script>
export default {
  name: "ChapterCard",
  props: {
    id: {
      type: String,
      required: true
    },
    photoUrl: {
      type: String,
      default: ""
    },
    url: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    // description: {
    //   type: String,
    //   default: ""
    // },
    location: {
      type: String,
      default: ""
    },
    memberCount: {
      type: Number,
      default: null
    },
    distance: {
      type: String,
      default: ""
    }
  },
  // computed: {
  //   truncatedDescription() {
  //     const maxLength = 130;
  //     return this.description.length > maxLength ? this.description.substring(0, maxLength) + "..." : this.description;
  //   }
  // },
  methods: {
    handleCardClick() {
      if (this.url) {
        window.open(this.url, "_blank");
      }
    }
  }
}
</script>

<template>
  <div class="suaw-chapter-card" @click="handleCardClick">
    <div class="suaw-chapter-card__content">
      <div class="suaw-chapter-card__image-container">
        <img class="suaw-chapter-card__image" :src="photoUrl" alt="Chapter image" loading="lazy" />
      </div>
      <div class="suaw-chapter-card__title-container">
        <h3 class="suaw-chapter-card__title">{{ title }}</h3>
      </div>
      <!-- <p class="suaw-chapter-card__description">{{ truncatedDescription }}</p> -->
      <div class="suaw-chapter-card__chips-container">
        <div class="suaw-chapter-card__chip">
          <b-icon icon="map-marker-alt" />
          <p>{{ location }}</p>
        </div>
        <div class="suaw-chapter-card__chip">
          <b-icon icon="globe" />
          <p>{{ distance }}</p>
        </div>
        <div class="suaw-chapter-card__chip info">
          <b-icon icon="user" />
          <p>{{ memberCount }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-chapter-card {
  background-color: #fff;
  min-width: 256px;
  max-width: 325px;
  // height: 365px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0.747px 1.743px 0px rgba(7, 22, 48, 0.02), 0px 1.796px 4.19px 0px rgba(7, 22, 48, 0.03), 0px 3.381px 7.889px 0px rgba(7, 22, 48, 0.04);
  cursor: pointer;
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1); /* Darker shadow on hover */
    transform: translateY(-3px); /* Slight lift effect */
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    row-gap: 8px;
    height: 100%;
  }
  &__image-container {
    border-radius: 8px;
    width: 100%;
    height: 146px;
    overflow: hidden;
    @media (max-width: 545px) {
      height: 175px;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__title-container {
    height: 51px;
  }
  &__title {
    color: #43444a;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    text-align: left;
    margin-bottom: auto;
  }
  &__description {
    color: #575860;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    text-align: left;
  }

  &__chips-container {
    display: grid;
    // grid-template-columns: 1fr 1fr; /* Create two columns */
    // grid-template-rows: auto auto; /* Two rows */
    row-gap: 8px;
    align-self: end;
  }

  // .suaw-chapter-card__chip:nth-child(1) {
  //   grid-column: span 2; /* Make the location chip span both columns */
  // }

  // .suaw-chapter-card__chip:nth-child(2) {
  //   grid-column: 1 / 2; /* Align the memberCount chip to the left on the second row */
  // }

  // .suaw-chapter-card__chip:nth-child(3) {
  //   grid-column: 2 / 3; /* Align the distance chip to the right on the second row */
  //   justify-self: end;
  // }

  &__chip {
    display: inline-flex;
    align-items: center;
    background: #F4F5F8;
    padding: 4px 8px;
    border-radius: 16px;
    max-width: max-content;
    p {
      margin: 0;
      color: var(--sem-color-text-medium, #575860);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%;
      letter-spacing: 0.14px;
    }
  }
  .info {
    color: #376BFF;
    p {
      color: #376BFF;
    }
  }
  @media (max-width: 1215px) {
    width: 100%;
  }
  @media (max-width: 853px) {
    justify-self: center;
  }
}
</style>
