<script>
import ChapterDiscovery from "./operations.gql";
import Page from "@/components/common/Page.vue";
import Section from "@/components/common/Section.vue";
import GooglePlaces from "@/components/forms/GooglePlaces.vue";
import ChapterCardGrid from "./components/ChapterCardGrid.vue";
import SkeletonCard from "./components/SkeletonCard.vue";
import { getGPS } from "../../location";
export default {
  name: "ChaptersPhysical",
  components: { Page, Section, GooglePlaces, ChapterCardGrid, SkeletonCard },
  data() {
    return {
      loadingQueriesCount: 0,
      chapterCards: [],
      userLocation: null,
      searchAddress: undefined,
      searchLat: 99,
      searchLng: 999,
      searchDescription: "You",
    };
  },
  apollo: {
    chapterCards: {
      query: ChapterDiscovery,
      variables() {
        return {
          lat: this.searchLat,
          lng: this.searchLng,
          unitSystem: this.$auth.user ? this.$auth.user.unit_system : "metric" //!!wonder if google places has this information as a default
        };
      },
      skip() {
        return !this.isSearchReady;
      },
      update(data) {
        if (data && data.chapterDiscovery && data.chapterDiscovery.value) {
          return data.chapterDiscovery.value; // Directly return the data as it is
        }
        return [];
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    isSearchReady() {
      return this.searchLat !== 99 && this.searchLng !== 999;
    },
    chapterGridHeading() {
      return `Chapters Near ${this.searchDescription}`;
    }
  },
  watch: {
    userLocation(val) {
      if (val !== null) {
        if (this.searchLat === 99 || this.searchLng == 999) {
          this.searchLat = val.lat;
          this.searchLng = val.lng;
        }
      }
      //else we didn't find a location and the user must search
    }
  },
  async created() {
    getGPS(position => {
      this.$log.info("user location known", position);
      if (position !== null) {
        this.userLocation = position;
      }
    });
  },
  methods: {
    onPlaceSelected(place) {
      this.searchLat = place.geometry.location.lat();
      this.searchLng = place.geometry.location.lng();
      this.searchDescription = place.formatted_address;
    }
  }
};
</script>

<template>
  <Page>
    <Section>
      <div>
        <h1>
          Find an
          <strong class="nowrap">In-Person</strong>
          Event
        </h1>
        <p>
          Browse our listings to sign up for a regular event that works with your schedule.
        </p>
      </div>
    </Section>
    <Section>
      <b-field>
        <GooglePlaces cities-only @updated:place="onPlaceSelected" />
      </b-field>
    </Section>
    <Section>
      <h2 class="suaw-chapter-card-grid-heading">{{ chapterGridHeading }}</h2>
      <!-- Show Skeletons while chapterCards are loading -->
      <div v-if="isLoading" class="suaw-chapter-card-grid-section">
        <SkeletonCard v-for="n in 16" :key="n" />
      </div>

      <!-- Show ChapterCardGrid when data is available -->
      <ChapterCardGrid v-else :cards="chapterCards" />
    </Section>

    <div class="events-container">
      <div class="boxed mb-4 cantfind">
        <h4 class="has-text-centered has-text-weight-medium">
          Can't find an event near you?
        </h4>
        <p class="has-text-centered has-text-grey">
          Search another location, try an<br class="is-hidden-tablet" />
          online event, or host your own.
        </p>

        <div class="button-row">
          <router-link
            tag="a"
            class="button is-info has-text-weight-bold "
            :to="{ name: 'events_virtual' }"
            exact
          >
          Search Online Events
          </router-link>
          <router-link
            tag="a"
            class="button is-pink has-text-weight-bold"
            :to="{ name: 'host' }"
            exact
          >
            Host an Event
          </router-link>
        </div>
      </div>
    </div>
  </Page>
</template>

<style>
.suaw-chapter-card-grid-heading {
  color: #43444A;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin-bottom: 24px;
}
.suaw-chapter-card-grid-section {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 32px;
  justify-content: center;
}
</style>
