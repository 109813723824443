import Vue from "vue";
import VueRouter from "vue-router";

import LogoutRedirector from "../components/common/LogoutRedirector.vue";
import { authGuard } from "../auth/authGuard";
import { organizerGuard } from "../auth/organizerGuard";
import { adminGuard } from "../auth/adminGuard";
import { eventDetailGuard } from "../auth/eventDetailGuard";
import accountFinishedCheck from "../auth/accountFinishedCheck";
import provisionalLoginCheck from "../auth/provisionalLoginCheck";

// components we don't want lazily loaded...
import NotFoundPage from "@/views/home/NotFoundPage.vue";
import Home from "@/views/home/Home.vue";
import Events from "@/views/events/Events.vue";
import EventsPhysical from "@/views/events/EventsPhysical.vue";
import EventsVirtual from "@/views/events/EventsVirtual.vue";
import ChaptersPhysical from "@/views/chapters/ChaptersPhysical.vue";
import EventDetail from "@/views/events/EventDetail.vue";
import SeriesLookup from "@/views/events/SeriesLookup.vue";

import Challenges from "@/views/challenges/Challenges.vue";
import ChallengeDetail from "@/views/challenges/ChallengeDetail.vue";
// import Prompts from "@/views/challenges/Prompts.vue";
import PromptDetail from "@/views/challenges/PromptDetail.vue";
import SubmissionDetail from "@/views/challenges/SubmissionDetail.vue";

import ChallengePromptDetailQA from "@/views/challenges/ChallengePromptDetailQA.vue";

import StripeCheckout from "@/views/stripe/Checkout.vue";
import StripeSuccess from "@/views/stripe/Success.vue";
import StripeCancel from "@/views/stripe/Cancel.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutRedirector
  },
  {
    path: "/articles",
    alias: "/resources",
    component: () => import("../views/resources/Resources.vue"),
    children: [
      {
        path: "",
        name: "articles",
        component: () => import("../components/resources/GeneralTab.vue")
      },
      {
        path: "organizers",
        name: "organizer_resources",
        beforeEnter: organizerGuard,
        component: () => import("../components/resources/OrganizerTab.vue")
      },
      {
        path: "admins",
        name: "admin_resources",
        beforeEnter: adminGuard,
        component: () => import("../components/resources/AdminTab.vue")
      }
    ]
  },
  {
    name: "article",
    path: "/articles/:slug",
    component: () => import("../views/resources/Post.vue")
  },
  {
    name: "articles_collection",
    path: "/articles/:slug/:id",
    component: () => import("../views/resources/Collection.vue")
  },
  // legacy article routes
  {
    name: "article-i-wrote-1000-words-for-30-days-and-here-is-what-happened",
    path: "/i-wrote-1000-words-for-30-days-and-here-is-what-happened",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "i-wrote-1000-words-for-30-days-and-here-is-what-happened" }
  },
  {
    name: "article-when-should-i-call-myself-a-writer",
    path: "/when-should-i-call-myself-a-writer",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "when-should-i-call-myself-a-writer" }
  },
  {
    name: "article-what-should-i-write-about-five-ways-to-find-new-writing-ideas",
    path: "/what-should-i-write-about-five-ways-to-find-new-writing-ideas",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "what-should-i-write-about-five-ways-to-find-new-writing-ideas" }
  },
  {
    name: "article-five-traits-of-an-unforgettable-scene",
    path: "/five-traits-of-an-unforgettable-scene",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "five-traits-of-an-unforgettable-scene" }
  },
  {
    name: "article-five-ways-to-create-new-settings-in-your-writing",
    path: "/five-ways-to-create-new-settings-in-your-writing",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "five-ways-to-create-new-settings-in-your-writing" }
  },
  {
    name: "article-submissions-the-harsh-reality-and-how-to-improve-your-odds",
    path: "/submissions-the-harsh-reality-and-how-to-improve-your-odds",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "submissions-the-harsh-reality-and-how-to-improve-your-odds" }
  },
  {
    name: "article-five-steps-to-improve-pacing-in-your-story",
    path: "/five-steps-to-improve-pacing-in-your-story",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "five-steps-to-improve-pacing-in-your-story" }
  },
  {
    name: "article-the-elements-of-storytelling-for-novelists",
    path: "/the-elements-of-storytelling-for-novelists",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "the-elements-of-storytelling-for-novelists" }
  },
  {
    name: "article-how-to-keep-your-writing-on-track",
    path: "/how-to-keep-your-writing-on-track",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "how-to-keep-your-writing-on-track" }
  },
  {
    name: "article-how-to-work-with-an-illustrator",
    path: "/how-to-work-with-an-illustrator",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "how-to-work-with-an-illustrator" }
  },
  {
    name: "article-should-you-self-publish-or-traditionally-publish",
    path: "/should-you-self-publish-or-traditionally-publish",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "should-you-self-publish-or-traditionally-publish" }
  },
  {
    name: "article-how-to-self-edit-in-six-painless-steps",
    path: "/how-to-self-edit-in-six-painless-steps",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "how-to-self-edit-in-six-painless-steps" }
  },
  {
    name: "article-staying-creative-while-the-world-is-burning",
    path: "/staying-creative-while-the-world-is-burning",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "staying-creative-while-the-world-is-burning" }
  },
  {
    name: "article-make-writing-a-habit-not-a-choice",
    path: "/make-writing-a-habit-not-a-choice",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "make-writing-a-habit-not-a-choice" }
  },
  {
    name: "article-how-to-find-a-literary-agent",
    path: "/how-to-find-a-literary-agent",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "how-to-find-a-literary-agent" }
  },
  {
    name: "article-between-editing-and-submission",
    path: "/between-editing-and-submission",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "between-editing-and-submission" }
  },
  {
    name: "article-how-to-edit-the-first-draft-of-your-novel",
    path: "/how-to-edit-the-first-draft-of-your-novel",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "how-to-edit-the-first-draft-of-your-novel" }
  },
  {
    name: "article-how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write",
    path: "/how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write" }
  },
  {
    name: "article-finding-and-working-with-a-beta-reader",
    path: "/finding-and-working-with-a-beta-reader",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "finding-and-working-with-a-beta-reader" }
  },
  {
    name: "article-how-to-get-a-book-cover-youre-proud-of",
    path: "/how-to-get-a-book-cover-youre-proud-of",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "how-to-get-a-book-cover-youre-proud-of" }
  },
  {
    name: "article-tricks-for-writing-under-pressure",
    path: "/tricks-for-writing-under-pressure",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "tricks-for-writing-under-pressure" }
  },
  {
    name: "article-how-to-get-feedback-on-your-writing",
    path: "/how-to-get-feedback-on-your-writing",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "how-to-get-feedback-on-your-writing" }
  },
  {
    name: "article-four-essential-steps-to-developing-your-writing-practice",
    path: "/four-essential-steps-to-developing-your-writing-practice",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "four-essential-steps-to-developing-your-writing-practice" }
  },
  {
    name: "article-seven-strategies-to-get-you-writing-again",
    path: "/seven-strategies-to-get-you-writing-again",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "seven-strategies-to-get-you-writing-again" }
  },
  {
    name: "article-the-writers-guide-to-copyright",
    path: "/the-writers-guide-to-copyright",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "the-writers-guide-to-copyright" }
  },
  {
    name: "article-10-best-practices-for-writing-from-home",
    path: "/10-best-practices-for-writing-from-home",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "10-best-practices-for-writing-from-home" }
  },
  {
    name: "article-the-writers-guide-to-editors",
    path: "/the-writers-guide-to-editors",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "the-writers-guide-to-editors" }
  },
  {
    name: "article-creating-your-writing-environment",
    path: "/creating-your-writing-environment",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "creating-your-writing-environment" }
  },
  {
    name: "article-five-ways-to-silence-your-inner-critic-while-drafting",
    path: "/five-ways-to-silence-your-inner-critic-while-drafting",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "five-ways-to-silence-your-inner-critic-while-drafting" }
  },
  {
    name: "article-shut-up-and-writes-nanowrimo-survival-guide",
    path: "/shut-up-and-writes-nanowrimo-survival-guide",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "shut-up-and-writes-nanowrimo-survival-guide" }
  },
  {
    name: "article-4-easy-steps-to-a-writing-plan",
    path: "/4-easy-steps-to-a-writing-plan",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "4-easy-steps-to-a-writing-plan" }
  },
  {
    name: "article-5-simple-habits-to-boost-your-writing",
    path: "/5-simple-habits-to-boost-your-writing",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "5-simple-habits-to-boost-your-writing" }
  },
  {
    name: "article-its-okay-to-be-edited-really",
    path: "/its-okay-to-be-edited-really",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "its-okay-to-be-edited-really" }
  },
  {
    name: "article-attending-the-muse-or-your-writing-discipline",
    path: "/attending-the-muse-or-your-writing-discipline",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "attending-the-muse-or-your-writing-discipline" }
  },
  {
    name: "article-five-geeky-hobbies-for-writers-that-arent-reading",
    path: "/five-geeky-hobbies-for-writers-that-arent-reading",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "five-geeky-hobbies-for-writers-that-arent-reading" }
  },
  {
    name: "article-after-the-first-draft-pro-tips-for-editing-your-work",
    path: "/after-the-first-draft-pro-tips-for-editing-your-work",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "after-the-first-draft-pro-tips-for-editing-your-work" }
  },
  {
    name: "article-beginning-tips-for-writers-working-styles-and-wip",
    path: "/beginning-tips-for-writers-working-styles-and-wip",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "beginning-tips-for-writers-working-styles-and-wip" }
  },
  {
    name: "article-perfectionism-distracts-you-from-writing-perfectly",
    path: "/perfectionism-distracts-you-from-writing-perfectly",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "perfectionism-distracts-you-from-writing-perfectly" }
  },
  {
    name: "article-conquer-procrastination-and-get-writing",
    path: "/conquer-procrastination-and-get-writing",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "conquer-procrastination-and-get-writing" }
  },
  {
    name: "article-beginning-tips-for-writers-the-basics",
    path: "/beginning-tips-for-writers-the-basics",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "beginning-tips-for-writers-the-basics" }
  },
  // Static routes for content pages from GraphCMS
  {
    name: "about",
    path: "/about",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "about" }
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "contact" }
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "faq" }
  },
  {
    name: "host-faq",
    path: "/host-faq",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "organizer-faq" }
  },
  {
    name: "organizer-faq",
    path: "/organizer-faq",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "organizer-faq" }
  },
  {
    name: "host",
    path: "/host",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "organizer" }
  },
  {
    name: "organizer",
    path: "/organizer",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "organizer" }
  },
  {
    name: "host_handbook",
    path: "/host-handbook",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "organizer-handbook" }
  },
  {
    name: "organizer_handbook",
    path: "/organizer-handbook",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "organizer-handbook" }
  },
  {
    name: "host_onboarding",
    path: "/host-onboarding",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "organizer-onboarding" }
  },
  {
    name: "organizer_onboarding",
    path: "/organizer-onboarding",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "organizer-onboarding" }
  },
  {
    name: "downloads",
    path: "/downloads",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "downloads" }
  },
  {
    name: "privacy_policy",
    path: "/privacy-policy",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "privacy-policy" }
  },
  {
    name: "tos",
    path: "/tos",
    alias: ["/terms", "/terms-of-service"],
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "tos" }
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "legal" }
  },
  {
    name: "code_of_conduct",
    path: "/code-of-conduct",
    component: () => import("../views/resources/Post.vue"),
    props: { slug: "code-of-conduct" }
  },
  {
    // FOR DEV, remove whenever
    name: "test",
    path: "/test",
    component: () => import("../views/test/Test.vue")
  },
  {
    // FOR DEV, remove whenever
    name: "buttons",
    path: "/buttons",
    component: () => import("../views/test/ButtonTest.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/home/Login.vue"),
    props: route => ({
      reason: route.params.reason
    })
  },
  {
    path: "/signup/",
    name: "signup",
    component: () => import("../views/home/SignUp.vue")
  },
  {
    path: "/dashboard",
    component: () => import("../views/user/Dashboard.vue"),
    beforeEnter: authGuard,
    children: [
      //{ #1011 Temporarily Hide Prompts BEGIN
        //path: "",
        //name: "dashboard",
        //beforeEnter: authGuard,
        //component: () => import("../components/dashboard/UpcomingTab.vue")
      {
        path: "",
        name: "dashboard_events",
        beforeEnter: authGuard,
        component: () => import("../components/dashboard/EventsTab.vue")
      },
      //}, #1011 Temporarily Hide Prompts END
      {
        path: "events",
        name: "dashboard_events",
        beforeEnter: authGuard,
        component: () => import("../components/dashboard/EventsTab.vue")
      },
      {
        path: "profile",
        name: "dashboard_profile",
        beforeEnter: authGuard,
        component: () => import("../components/dashboard/ProfileTab.vue")
      },
      {
        path: "challenges",
        name: "dashboard_challenges",
        beforeEnter: authGuard,
        component: () => import("../components/dashboard/ChallengesTab.vue")
      },
      //{ #1011 Temporarily Hide Prompts BEGIN
        //path: "prompts",
        //name: "dashboard_prompts",
        //beforeEnter: authGuard,
        //component: () => import("../components/dashboard/PromptsTab.vue")
      //}, #1011 Temporarily Hide Prompts END
      {
        path: "inbox",
        name: "dashboard_inbox",
        beforeEnter: authGuard,
        component: () => import("../components/dashboard/InboxTab.vue")
      },
      {
        path: "inbox/:slug/:topic_id",
        name: "dashboard_inbox_topic",
        beforeEnter: authGuard,
        component: () => import("../components/dashboard/InboxTopic.vue")
      }
    ]
  },
  {
    path: "/icons",
    name: "icons",
    component: () => import("../views/test/Icons.vue"),
    beforeEnter: adminGuard
  },
  {
    path: "/admin/chapters",
    name: "admin_chapters",
    component: () => import("../views/chapters/ChaptersAdministration.vue"),
    beforeEnter: adminGuard
  },
  {
    path: "/users",
    name: "users",
    component: () => import("../views/user/UsersAdministration.vue"),
    beforeEnter: adminGuard
  },
  {
    path: "/users/:username",
    name: "user_profile",
    component: () => import("../views/user/UserProfile.vue")
  },
  {
    path: "/events/admin",
    name: "event_admin",
    beforeEnter: adminGuard,
    component: () => import("../views/events/EventTemplateAdministration.vue")
  },
  {
    path: "/events/create",
    name: "event_create",
    beforeEnter: organizerGuard,
    component: () =>
      import(
        /* webpackChunkName: "event_create" */
        "../views/events/EventCreate.vue"
      )
  },
  // {
  //   path: "/events/in-person/:lat?/:lng?",
  //   name: "events_physical",
  //   component: EventsPhysical
  // },
  {
    path: "/events/in-person/:lat?/:lng?",
    name: "events_physical",
    component: ChaptersPhysical
  },

  {
    path: "/events/online",
    name: "events_virtual",
    component: EventsVirtual
  },
  {
    path: "/events",
    name: "events",
    component: Events
  },
  {
    path: "https://www.meetup.com/pro/shut-up-write/",
    name: "meetup_physical"
  },
  {
    path: "https://www.meetup.com/shutupandwriteonlineevents/",
    name: "meetup_virtual"
  },
  {
    path: "/series-lookup/:event_template_id",
    name: "series_lookup",
    component: SeriesLookup,
    props: route => ({
      eventTemplateId: parseInt(route.params.event_template_id)
    })
  },
  {
    path: "/series/:slug/:event_template_id",
    name: "series",
    component: SeriesLookup,
    props: route => ({
      eventTemplateId: parseInt(route.params.event_template_id)
    })
  },
  {
    // NEW EVENT DETAIL
    path: "/events/:slug-writing-group/:event_id",
    name: "event_detail",
    beforeEnter: eventDetailGuard,
    component: EventDetail,
    props: route => ({ eventId: parseInt(route.params.event_id) })
  },
  {
    path: "/events/:slug/:event_id",
    name: "event_detail_non_seo",
    beforeEnter: eventDetailGuard,
    component: EventDetail,
    props: route => ({ eventId: parseInt(route.params.event_id) })
  },
  /***** FORUMS *****/
  {
    path: "/forums/topic/:slug/:topic_id",
    name: "forum_topic",
    component: () =>
      import(/* webpackChunkName: "forums" */ "../views/forums/Topic.vue")
  },
  {
    path: "/forums/:slug/:forum_id",
    name: "forum_topics",
    component: () =>
      import(/* webpackChunkName: "forums" */ "../views/forums/Forum.vue")
  },
  {
    path: "/forums/",
    name: "forum_categories",
    component: () =>
      import(/* webpackChunkName: "forums" */ "../views/forums/Categories.vue")
  },
  /***** CHALLENGES ******/
  {
    path: "/challenges/:challenge_slug/:prompt_slug/submissions/:submission_id",
    name: "challenge_submission_detail",
    component: SubmissionDetail
  },
  {
    path: "/prompts/submissions/:submission_id",
    name: "submission_detail",
    component: SubmissionDetail
  },
  {
    path: "/challenges/:slug",
    name: "challenges_detail",
    component: ChallengeDetail
  },
  {
    path: "/challenges/:slug/:challenge_id",
    name: "challenges_detail_legacy", //ONLY here so that old email links work
    component: ChallengeDetail
  },
  {
    path: "/challenges/:challenge_slug/:prompt_slug/:prompt_id/qa",
    name: "challenges_prompt_qa",
    beforeEnter: authGuard,
    component: ChallengePromptDetailQA
  },
  {
    path: "/challenges/:challenge_slug/:prompt_slug/:prompt_id",
    name: "challenges_prompt",
    component: PromptDetail
  },
  {
    path: "/prompts/:prompt_id",
    name: "prompt_detail_naked",
    component: PromptDetail
  },
  {
    path: "/prompts",
    name: "prompts",
    //#1011 Temporarily Hide Prompts
    //component: Prompts
    redirect: "/"
    //#1011 Temporarily Hide Prompts END
  },
  {
    path: "/challenges/",
    name: "challenges",
    component: Challenges
  },
  {
    path: "/reset-password",
    name: "reset_password",
    component: () => import("../views/home/ResetPassword.vue")
  },
  {
    path: "/reset-password/:token",
    name: "reset_password_token",
    component: () => import("../views/home/ResetPassword.vue")
  },
  {
    path: "/complete-account",
    name: "complete_account",
    component: () => import("../views/home/CompleteAccount.vue")
  },
  {
    path: "/complete-account/:token",
    name: "complete_account_token",
    component: () => import("../views/home/CompleteAccount.vue")
  },
  {
    path: "/stripe/checkout",
    name: "stripe_checkout",
    component: StripeCheckout
  },
  {
    path: "/stripe/success",
    name: "stripe_success",
    component: StripeSuccess
  },
  {
    path: "/stripe/cancel",
    name: "stripe_cancel",
    component: StripeCancel
  },
  {
    path: "*",
    name: "404",
    component: NotFoundPage
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach(accountFinishedCheck);
router.beforeEach(provisionalLoginCheck);

export default router;
