<script>
export default {
  name: "SkeletonCard"
};
</script>

<template>
  <div class="suaw-chapter-card">
    <div class="suaw-chapter-card__content">
      <!-- Image Skeleton -->
      <div class="suaw-chapter-card__image-container">
        <b-skeleton :width="'100%'" :height="'146px'" />
      </div>
      <!-- Title Skeleton -->
      <div class="suaw-chapter-card__title-container">
        <b-skeleton :width="'60%'" :height="'20px'" class="mb-2" />
      </div>
      <!-- Chips Skeleton -->
      <div class="suaw-chapter-card__chips-container">
        <b-skeleton :width="'40%'" :height="'14px'" class="mb-2" />
        <b-skeleton :width="'30%'" :height="'14px'" class="mb-2" />
        <b-skeleton :width="'20%'" :height="'14px'" class="mb-2" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-chapter-card {
  background-color: #fff;
  min-width: 256px;
  max-width: 325px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0.747px 1.743px 0px rgba(7, 22, 48, 0.02),
    0px 1.796px 4.19px 0px rgba(7, 22, 48, 0.03),
    0px 3.381px 7.889px 0px rgba(7, 22, 48, 0.04);
  cursor: pointer;
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  @media (max-width: 1215px) {
    width: 100%;
  }
  @media (max-width: 853px) {
    justify-self: center;
  }
}

.suaw-chapter-card__content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  row-gap: 8px;
  height: 100%;
}

.suaw-chapter-card__image-container {
  border-radius: 8px;
  width: 100%;
  height: 146px;
  overflow: hidden;
}

.suaw-chapter-card__title-container {
  height: 51px;
}

.suaw-chapter-card__chips-container {
  display: grid;
  row-gap: 8px;
  align-self: end;
}

</style>